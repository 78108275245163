import React from "react";

const Error = () => {
  return (
    <div>
      <h1 className="d-flex center-flex aligns-items-center justify-content-center">Error page</h1>
    </div>
  );
};

export default Error;