import './App.css';
import Index from './routes';

function App() {
  return (
      <Index />
  )
}

export default App;
