import React from "react";

const Next = () => {
  return (
    <div>
      <h1 className="d-flex center-flex aligns-items-center justify-content-center">Proximamente...</h1>
    </div>
  );
};

export default Next;